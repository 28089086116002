.indemnizaciones-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  margin: 20px 20px 20px 265px;
  padding: 20px;
}

.indemnizacion-box {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  padding: 25px;
  transition: transform 0.2s;
}

.indemnizacion-box:hover {
  transform: translateY(-5px);
}

.indemnizacion-box h2 {
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Reutilizando los estilos del search-bar de eliminarGuia.css */
.search-bar {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
}

.search-input,
.carrier-select {
  height: 42px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.search-input {
  flex: 2;
}

.carrier-select {
  flex: 1;
  background: white;
}

.search-button {
  height: 42px;
  padding: 0 20px;
  border: none;
  border-radius: 6px;
  background: #3f51b5;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;
}

.search-button:hover {
  background: #303f9f;
}

@media (max-width: 1200px) {
  .indemnizaciones-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .indemnizaciones-container {
    margin: 20px;
  }
  
  .search-bar {
    flex-direction: column;
  }

  .search-input,
  .carrier-select,
  .search-button {
    width: 100%;
  }
}
