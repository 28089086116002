.transferencia-container {
    width: 100%;
    padding: 20px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.saldo-card {
    background: linear-gradient(135deg, #7380ec 0%, #5b6bdf 100%);
    color: white;
    margin-bottom: 20px;
}

.saldo-header {
    display: flex;
    align-items: center;
    gap: 20px;
}

.saldo-icon {
    font-size: 48px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
}

.saldo-card h2 {
    color: rgba(255, 255, 255, 0.9);
    margin: 0;
    font-size: 18px;
}

.saldo-card h3 {
    color: white;
    margin: 5px 0 0;
    font-size: 32px;
    font-weight: bold;
}

.nueva-transferencia-btn {
    margin: 20px 0;
    height: 48px;
    padding: 0 30px;
    font-size: 16px;
    border-radius: 8px;
    background: #7380ec;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.nueva-transferencia-btn:hover {
    background: #5b6bdf;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.transferencias-card {
    margin-bottom: 30px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.transferencias-card .ant-card-head {
    padding: 16px 24px;
    border-bottom: 2px solid #f0f0f0;
}

.transferencias-card .ant-card-body {
    padding: 24px;
}

.card-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #333;
}

.custom-modal-transferencia .ant-modal-content {
    border-radius: 12px;
    padding: 0;
    overflow: hidden;
}

.custom-modal-transferencia .ant-modal-header {
    border-radius: 12px 12px 0 0;
    padding: 20px 24px;
    background: #7380ec;
    border-bottom: none;
}

.custom-modal-transferencia .ant-modal-title {
    color: white;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-modal-transferencia .ant-modal-body {
    padding: 24px;
}

.custom-modal-transferencia .ant-modal-footer {
    padding: 20px 24px;
    border-top: 1px solid #f0f0f0;
    background: #fafafa;
}

.custom-modal-transferencia .ant-btn {
    height: 40px;
    padding: 0 25px;
    font-size: 16px;
    border-radius: 8px;
}

.custom-modal-transferencia .ant-btn-primary {
    background: #7380ec;
    border-color: #7380ec;
}

.custom-modal-transferencia .ant-btn-primary:hover {
    background: #5b6bdf;
    border-color: #5b6bdf;
}

.saldo-info {
    background: #e3f2fd;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.saldo-info h2 {
    margin: 0;
    color: #333;
}

.saldo-info h3 {
    margin: 10px 0 0;
    font-size: 24px;
    color: #2196f3;
}

.historial-transferencias {
    margin-top: 20px;
}

.historial-transferencias h2 {
    margin-bottom: 20px;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-weight: 500;
}

.detalle-sucursal {
    padding: 24px;
    background: #f8f9fa;
    border-radius: 12px;
    border: 1px solid #e8e8e8;
    margin: 20px 0;
    box-shadow: inset 0 0 0 1px rgba(0,0,0,0.05);
}

.detalle-sucursal h3 {
    margin-bottom: 15px;
    color: #333;
}

.detalle-sucursal p {
    margin-bottom: 12px;
    line-height: 1.6;
    color: #666;
    font-size: 16px;
    padding: 8px 0;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

.detalle-sucursal p:last-child {
    border-bottom: none;
}

.detalle-sucursal strong {
    color: #7380ec;
    margin-right: 5px;
}

.confirm-details {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #e8e8e8;
}

.confirm-details h3 {
    color: #333;
    margin-bottom: 20px;
    font-size: 18px;
}

.detail-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 0;
    border-bottom: 1px solid #eee;
}

.detail-item:last-child {
    border-bottom: none;
}

.detail-item strong {
    color: #666;
    min-width: 150px;
}

.detail-item p {
    margin: 0;
    color: #333;
    font-weight: 500;
    text-align: right;
    flex: 1;
}

.detail-item .monto {
    color: #52c41a;
    font-size: 1.2em;
    font-weight: bold;
}

@media (max-width: 768px) {
    .transferencia-container {
        padding: 15px;
    }

    .saldo-info h3 {
        font-size: 20px;
    }

    .nueva-transferencia-btn {
        width: 100%;
    }
}

/* Animaciones para los elementos que aparecen */
.saldo-card, 
.nueva-transferencia-btn, 
.transferencias-card {
    animation: fadeInUp 0.5s ease;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Estilos mejorados para las tablas */
.ant-table {
    font-size: 16px;
}

.ant-table-thead > tr > th {
    padding: 16px;
    font-size: 16px;
    font-weight: 600;
    background: #f0f2ff;
    color: #333;
}

.ant-table-tbody > tr > td {
    padding: 16px;
    font-size: 15px;
}

.ant-table-tbody > tr:hover > td {
    background: #f6f8ff !important;
}

/* Mejoras en los estados de la tabla */
.ant-badge-status-dot {
    width: 8px;
    height: 8px;
}

.ant-badge-status-text {
    font-size: 15px;
}

/* Ajustes para las tablas */
.transferencias-card .ant-table-wrapper {
    min-height: 400px;  /* Altura mínima para ambas tablas */
}

/* Ajustes para el modal */
.custom-modal-transferencia {
    top: 50px !important;  /* Ajustar posición vertical del modal */
}

.custom-modal-transferencia .ant-modal-wrap {
    display: flex;
    align-items: flex-start;  /* Alinear al inicio en lugar de al centro */
    padding-top: 50px;  /* Espacio superior */
}

/* Asegurar que ambas tablas tengan el mismo tamaño */
.transferencias-card .ant-table {
    min-height: 300px;  /* Altura mínima para la tabla */
}

.transferencias-card .ant-table-body {
    min-height: 250px;  /* Altura mínima para el cuerpo de la tabla */
}
